<template>
  <div class="login">
    <div class="form">

      <h1>Connectez-vous</h1>

      <div v-if="error" class="info error">{{ error }}</div>
      <form @submit.prevent="login">
        <div class="input">
          <label>Adresse e-mail</label>
          <input type="email" v-model="email" placeholder="">
        </div>
        <div class="input">
          <label>Mot de passe</label>
          <input type="password" v-model="password" placeholder="">
        </div>
        <bouton txt="Continuer" big="true" :loader="loader">
<!--          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 3c5.514 0 10 3.592 10 8.007 0 4.917-5.144 7.961-9.91 7.961-1.937 0-3.384-.397-4.394-.644-1 .613-1.594 1.037-4.272 1.82.535-1.373.722-2.748.601-4.265-.837-1-2.025-2.4-2.025-4.872 0-4.415 4.486-8.007 10-8.007zm0-2c-6.338 0-12 4.226-12 10.007 0 2.05.739 4.063 2.047 5.625.055 1.83-1.023 4.456-1.993 6.368 2.602-.47 6.301-1.508 7.978-2.536 1.417.345 2.774.503 4.059.503 7.084 0 11.91-4.837 11.91-9.961-.001-5.811-5.702-10.006-12.001-10.006zm-4.449 10.151c.246.277.369.621.369 1.034 0 .529-.208.958-.624 1.289-.416.33-.996.495-1.74.495-.637 0-1.201-.123-1.69-.367l.274-1.083c.494.249.993.375 1.501.375.293 0 .521-.056.686-.167.315-.214.334-.646.023-.892-.149-.117-.404-.236-.769-.357-1.097-.366-1.645-.937-1.645-1.716 0-.503.202-.917.604-1.243.404-.325.943-.488 1.614-.488.586 0 1.096.099 1.535.298l-.299 1.049c-.401-.187-.82-.28-1.254-.28-.267 0-.476.052-.627.153-.299.204-.293.57-.035.787.126.107.428.246.91.416.532.187.92.42 1.167.697zm12.205-.021c-.249-.28-.645-.518-1.181-.706-.475-.168-.776-.307-.899-.41-.243-.205-.249-.545.032-.738.146-.099.352-.148.609-.148.464 0 .87.104 1.274.295l.316-1.111-.022-.012c-.441-.199-.962-.3-1.55-.3-.675 0-1.225.166-1.632.495-.41.33-.618.757-.618 1.267 0 .791.562 1.377 1.67 1.745.357.122.612.239.757.353.292.231.28.637-.022.841-.157.108-.381.162-.667.162-.549 0-1.042-.145-1.522-.39l-.29 1.147c.549.273 1.122.38 1.728.38.749 0 1.34-.168 1.759-.502.422-.334.636-.776.636-1.313 0-.418-.127-.772-.378-1.055zm-6.644-3.005l-1.228 3.967-1.014-3.967h-1.791l-.366 5.75h1.229l.204-4.642h.018s.702 2.878 1.178 4.547h1.031c.794-2.419 1.261-3.936 1.399-4.547h.026c0 .813.045 2.36.136 4.642h1.298l-.309-5.75h-1.811z"/></svg>-->
        </bouton>
      </form>

    </div>
  </div>
</template>

<script>
// import router from "@/router";

export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      loader: false,
      error: null
    }
  },
  mounted() {
    // document.cookie = "username=John Doe; expires=Thu, 18 Dec 2023 12:00:00 UTC; httpOnly=true";
  },
  methods: {
    login() {
      if(this.email && this.password) {
        this.loader = true
        this.error = false
        this.$http.post('/auth', { email: this.email, password: this.password }).then((data) => {
          let token =  data.data.token
          localStorage.setItem('creative_token', token)
          console.log(token)
          this.$httpStatum.defaults.headers.common['Authorization'] = `Bearer ${token}`
          this.$store.dispatch('creativeAuth/login', token)
          this.$router.push({ name: 'Home' })
          
          this.loader = false
        }).catch((error) => {
          this.loader = false
          this.error = error.response.data.error
        })
      }
    }
  }
}
</script>

<style scoped>

.login { position: fixed; top: 0; right: 0; bottom: 0; left: 0; padding-bottom: 36px; display: flex; align-items: center; justify-content: center; flex-direction: column }
.form { width: 320px; }
input { width: 100%; }
.bouton { width: 100%; margin-top: 24px; }

</style>